import React, {useState} from "react";
import {Box, Chip, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {QRCodeSVG} from "qrcode.react";
import styled from "styled-components";
import {Button, IconStart} from "../../base";
import QrCodeIcon from '@mui/icons-material/QrCode';
import {useSessionListenUrl} from "./hooks/useSessionListenUrl";

interface Props {
    translationLanguages: string[] | undefined;
    supportedLanguages: Record<string, string> | undefined;
}

const StyledDialogTitle = styled(DialogTitle)`
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
`;

const StyledDialogContent = styled(DialogContent)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 36px;
`;

const QRCodeButton = styled(Button)`
    text-transform: lowercase;
    background-color: #fff;
    color: #101010;
    border: 1px solid #101010;
`;

const CloseDialogButton = styled(Button)`
    text-transform: lowercase;
`;

export function RoomQRCode({ translationLanguages, supportedLanguages }: Props) {
    const [open, setOpen] = useState(false);
    const url = useSessionListenUrl();
    const scale = 1.5;

    return (
        <>
            <QRCodeButton onClick={() => setOpen(true)}>
                <IconStart size="medium"><QrCodeIcon /></IconStart>
                <span>QR code</span>
            </QRCodeButton>
            <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
                <StyledDialogTitle>Connect to the translation</StyledDialogTitle>
                <StyledDialogContent>
                    <QRCodeSVG value={url} size={600} style={{ display: 'flex', flex: 1 }} />
                    <Box display="flex" flexDirection="row" gap={4}>
                        {supportedLanguages && translationLanguages?.map(translationLanguage => (
                            <Chip key={translationLanguage}
                                  label={supportedLanguages[translationLanguage]!}
                                  sx={{
                                      height: `${scale * 32}px`,
                                      borderRadius: `${scale * 16}px`,
                                      '& .MuiChip-label': {
                                          paddingRight: `${scale * 12}px`,
                                          paddingLeft: `${scale * 12}px`,
                                          fontSize: `${scale * 0.8125}rem`,
                                      },
                                      '& .MuiChip-avatar': {
                                          width: `${scale * 24}px`,
                                          height: `${scale * 24}px`,
                                          fontSize: `${scale * 0.75}rem`,
                                      },
                                  }}
                            />
                        ))}
                    </Box>
                </StyledDialogContent>
                <DialogActions>
                    <CloseDialogButton variant="secondary" onClick={() => setOpen(false)}>
                        Close
                    </CloseDialogButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
