class SilentOscillator {
    private audioContext: AudioContext;
    private oscillator: OscillatorNode;

    constructor() {
        this.audioContext = new AudioContext();
        this.oscillator = this.audioContext.createOscillator();
        this.oscillator.frequency.setValueAtTime(0.0001, this.audioContext.currentTime); // Set frequency to a very low value
        this.oscillator.connect(this.audioContext.destination);
    }

    start() {
        this.oscillator.start();
    }

    stop() {
        this.oscillator.stop();
    }
}

export class Playback {
    private queue: string[] = [];
    private isPlaying = false;
    private audio: HTMLAudioElement | undefined;
    private silentAudio: HTMLAudioElement;
    private silentOscillator: SilentOscillator;

    constructor(private onChangeEnabled: (enabled: boolean) => void) {
        this.toggle = this.toggle.bind(this);
        this.silentAudio = new Audio('data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEARKwAABCxAgAEABAAZGF0YQAAAAA='); // 1-second silent audio
        this.silentOscillator = new SilentOscillator();
    }

    isEnabled() {
        return !!this.audio;
    }

    /**
     * Initialize the audio player on user action
     * Otherwise, the browser on mobile will block the audio from playing
     */
    enable() {
        if (this.audio) {
            this.disable();
        }

        this.audio = new Audio();
        this.audio.addEventListener('ended', this.playNextInQueue.bind(this));
        this.silentOscillator.start();

        this.onChangeEnabled(true);
    }

    disable() {
        if (this.audio) {
            this.audio.removeEventListener('ended', this.playNextInQueue.bind(this));
            this.audio.remove();
            this.audio = undefined;
            this.silentOscillator.stop();

            this.onChangeEnabled(false);
        }
    }

    toggle() {
        if (this.isEnabled()) {
            this.disable();
        } else {
            this.enable();
        }
    }

    async add(audio: ArrayBuffer) {
        if (!this.isEnabled()) {
            return;
        }

        const blob = new Blob([audio], { type: 'audio/mp3' });
        const url = URL.createObjectURL(blob);
        this.queue.push(url);

        if (!this.isPlaying && this.audio) {
            this.isPlaying = true;
            await this.playNextInQueue();
        }
    }

    private async playNextInQueue() {
        if (this.queue.length > 0 && this.audio) {
            const nextAudio = this.queue.shift();

            if (nextAudio) {
                this.audio.src = nextAudio;
                this.audio.play().catch(console.error);
            } else {
                this.isPlaying = false;
            }
        } else {
            this.isPlaying = false;
            this.silentAudio.play().catch(console.error);
        }
    }
}
