import {useEffect, useMemo, useState} from "react";
import {EventType, Playback, WebSocketClient} from "../../../apis";

export function usePlayback(websocket: WebSocketClient) {
    // add to re-render on changing the state
    const [_enabled, setEnabled] = useState(false);
    const playback = useMemo(() => new Playback(setEnabled), []);

    useEffect(() => {
        const onMessage = async (data: ArrayBuffer) => {
            await playback.add(data);
        };

        websocket.addEventListener(EventType.Audio, onMessage);

        return () => {
            websocket.removeEventListener(EventType.Audio, onMessage);
        };
    });

    return { playback };
}
