import React, { useState } from 'react';
import styled from 'styled-components';
import { LoginNavButton, NavButton } from "./nav-buttons";
import { Globe2, Menu } from "lucide-react";
import { Drawer, IconButton } from "@mui/material";

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0;
    min-height: 40px;
    border-bottom: 1px solid #e7e7e7;
    justify-content: space-between;
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    color: #4f46e5;
`;

const LogoText = styled.span`
    font-size: 1rem;
    font-weight: bold;
    color: #101010;
`;

const MenuIcon = styled(Menu)`
    display: none;
    cursor: pointer;

    @media (max-width: 768px) {
        display: block;
    }
`;

const Nav = styled.nav`
    display: flex;
    gap: 10px;

    @media (max-width: 768px) {
        display: none;
    }
`;

export function Header() {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <Container>
            <Logo>
                <Globe2 size={24} />
                <LogoText>SAI</LogoText>
            </Logo>
            <MenuIcon size={24} onClick={toggleDrawer} />
            <Nav>
                <NavButton to="/panel/sessions">Sessions</NavButton>
                <NavButton to="/panel/profile/details">Profile</NavButton>
                <LoginNavButton />
            </Nav>
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
                <NavButton to="/panel/sessions" onClick={toggleDrawer}>Sessions</NavButton>
                <NavButton to="/panel/profile/details" onClick={toggleDrawer}>Profile</NavButton>
                <LoginNavButton onClick={toggleDrawer} />
            </Drawer>
        </Container>
    );
}
