import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {AuthProvider} from "./context";
import {LoginPage} from "./components/pages/login";
import {RegisterPage} from "./components/pages/register";
import {SessionPage} from "./components/pages/session";
import {GlobalStyle} from "./GlobalStyle";
import {SessionListenPage, SessionListenPageRedirect} from "./components/pages/session-listen";
import {ProtectedRoute, NonProtectedRoute} from "./components/base";
import {SessionTablePage} from "./components/pages/session-table";
import {ProfileDetailsPage} from "./components/pages/profile-details";
import { ProfileBillingPage } from './components/pages/profile-billing';
import {Page, ProfilePage} from './components/layout';
import {HomePage} from "./components/pages/home";
import { ResetPasswordPage } from './components/pages/password-reset';
import {NewPasswordPage} from "./components/pages/new-password";

export function App() {
  return (
    <>
      <GlobalStyle />
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<NonProtectedRoute><LoginPage /></NonProtectedRoute>} />
            <Route path="/register" element={<NonProtectedRoute><RegisterPage /></NonProtectedRoute>} />
            <Route path="/reset-password" element={<NonProtectedRoute><ResetPasswordPage /></NonProtectedRoute>} />
            <Route path="/password-update" element={<NonProtectedRoute><NewPasswordPage /></NonProtectedRoute>} />

            <Route path="/:sessionName" element={<SessionListenPageRedirect />} />

            <Route path="/panel" element={<Page />}>
              <Route path="" element={<ProtectedRoute><Navigate to="/panel/sessions" replace /></ProtectedRoute>} />

              <Route path="sessions">
                <Route path="" element={<ProtectedRoute><SessionTablePage /></ProtectedRoute>} />
                <Route path=":sessionName" element={<ProtectedRoute><SessionPage /></ProtectedRoute>} />

                {/* both authenticated and non-authenticated users should have access to this routes */}
                <Route path=":sessionName/listen" element={<SessionListenPage />} />
              </Route>

              <Route path="profile" element={<ProfilePage />}>
                <Route path="" element={<ProtectedRoute><Navigate to="details" replace /></ProtectedRoute>} />
                <Route path="details" element={<ProtectedRoute><ProfileDetailsPage /></ProtectedRoute>} />
                <Route path="billing" element={<ProtectedRoute><ProfileBillingPage /></ProtectedRoute>} />
              </Route>
            </Route>

            {/* both authenticated and non-authenticated users should have access to this routes */}
            <Route path="/" element={<HomePage />} />
          </Routes>
        </Router>
      </AuthProvider>
    </>
  );
}
