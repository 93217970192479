import React, {useState} from 'react';
import {Actions} from "../../layout";
import {Box, Chip, Grid2} from '@mui/material';
import {useAsync} from 'react-use';
import {useParams} from 'react-router-dom';
import {usePlayback, useWebsocket} from "../../base/hooks";
import {PlayPauseButton} from "../../base/PlayPauseButton";
import {useApiClient} from "../../base/hooks/useApiClient";
import {Stenographer} from "../../base";
import {EventType} from "../../../apis";
import {LanguageSelector} from "./LanguageSelector";
import {SessionStatusIndicator} from "./SessionStatusIndicator";

export function SessionListenPage() {
    const [language, setLanguage] = useState<string>('');
    const [isPlaying, setIsPlaying] = useState(false);
    const { sessionName } = useParams<{ sessionName: string }>();
    const websocket = useWebsocket(`/sessions/${sessionName}/${language}`, !!language);
    const apiClient = useApiClient();
    const { playback } = usePlayback(websocket);

    const { value: translationLanguages } = useAsync(async () => {
        const response = await apiClient.get(`sessions/${sessionName}/translation-languages`);

        if (!response.ok) {
            console.error('Error fetching session data');
        }

        const { translationLanguages } = await response.json();
        setLanguage(translationLanguages[0]);

        return translationLanguages as string[];
    });

    const { value: supportedLanguages } = useAsync(async () => {
        const response = await apiClient.get('supported-languages');

        if (!response.ok) {
            console.error('Error fetching session data');
        }

        const data = await response.json() as { supportedLanguages: Record<string, string> };

        return data.supportedLanguages;
    });

    const handleJoin = async () => {
        playback.enable();
        await websocket.send(EventType.StartListening);

        setIsPlaying(true);
    };

    const onStopPlaying = async () => {
        playback.disable();
        await websocket.send(EventType.StopListening);
        setIsPlaying(false);
    };

    const languagesLoaded = translationLanguages && supportedLanguages;

    return (
        <Grid2 container direction="column" gap={2} sx={{ height: '100%' }}>
            <Grid2 size={{ xs: 12 }}>
                <Chip
                    variant="outlined"
                    size="small"
                    label="Keep the tab active to get the translation"
                    sx={{ color: '#6e6e7f', width: '100%' }}
                />
            </Grid2>
            <Grid2 size={{ xs: 12 }} sx={{ flexGrow: 1, height: '10%', overflow: 'auto' }}>
                <Stenographer websocket={websocket} eventType={EventType.Translation} />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
                <Actions>
                    <LanguageSelector
                        loading={!languagesLoaded}
                        disabled={isPlaying}
                        value={language}
                        onChange={setLanguage}
                        options={translationLanguages?.map((availableLanguage) => ({
                            value: availableLanguage,
                            label: supportedLanguages?.[availableLanguage] ?? availableLanguage,
                        })) ?? []}
                    />

                    <Box display="flex" flexDirection="row" alignItems="center">
                        <SessionStatusIndicator websocket={websocket} />
                        <PlayPauseButton
                            playText="listen"
                            pauseText="stop"
                            onStart={handleJoin}
                            onStop={onStopPlaying}
                        />
                    </Box>
                </Actions>
            </Grid2>
        </Grid2>
    );
}
