import { io, Socket } from "socket.io-client";

export enum EventType {
    Transcript = 'transcript',
    Translation = 'translation',
    Audio = 'audio',
    StartSession = 'start-session',
    StopSession = 'stop-session',
    SessionStarted = 'session-started',
    SessionStopped = 'session-stopped',
    StartListening = 'start-listening',
    StopListening = 'stop-listening',
    ListenersChanged = 'listeners-changed',
    MasterPlaybackLanguage = 'master-playback-language',
}

type EventListener = (event: any) => any;

const eventListeners: Record<EventType, { isValidData: (data: unknown) => boolean }> = {
    [EventType.Transcript]: { isValidData: (data) => typeof data === 'string' },
    [EventType.Translation]: { isValidData: (data) => typeof data === 'string' },
    [EventType.Audio]: { isValidData: (data) => data instanceof ArrayBuffer },
    [EventType.StartSession]: { isValidData: (data) => true },
    [EventType.StopSession]: { isValidData: (data) => true },
    [EventType.SessionStarted]: { isValidData: (data) => true },
    [EventType.SessionStopped]: { isValidData: (data) => true },
    [EventType.StartListening]: { isValidData: (data) => true },
    [EventType.StopListening]: { isValidData: (data) => true },
    [EventType.ListenersChanged]: { isValidData: (data) => true },
    [EventType.MasterPlaybackLanguage]: { isValidData: (data) => true },
};

export class WebSocketClient {
    private path: string | undefined;
    private socket: Socket | undefined;
    private waitForWebsocket: Promise<void> | undefined;
    private eventListeners: Partial<Record<EventType, EventListener[]>> = {};

    addEventListener<T extends EventType>(type: T, listener: EventListener) {
        if (!this.eventListeners[type]) {
            this.eventListeners[type] = [];
        }

        this.eventListeners[type]!.push(listener);
    }

    removeEventListener<T extends EventType>(type: T, listener: EventListener) {
        if (!this.eventListeners[type]) {
            return;
        }

        this.eventListeners[type] = this.eventListeners[type]!.filter(
            (existingListener) => existingListener !== listener,
        );
    }

    waitForConnection() {
        return this.waitForWebsocket;
    }

    connect(path: string) {
        if (this.path === path && this.socket?.connected) {
            return this.waitForWebsocket;
        }

        this.path = path;

        this.waitForWebsocket = new Promise<void>((resolve, reject) => {
            const socket = io(`${process.env.REACT_APP_WEBSOCKET_URL}${path}`);

            socket.on('connect', () => {
                this.socket = socket;
                resolve();
            });

            socket.on('connect_error', (error) => {
                reject(error);
            });

            Object.keys(eventListeners).forEach((listener) => {
                this.listenSocket(socket, listener as EventType, eventListeners[listener as EventType]!.isValidData);
            });
        });

        return this.waitForWebsocket;
    }

    async send(eventName: EventType, data?: any): Promise<void> {
        await this.waitForConnection();

        if (!this.socket) {
            throw new Error('Socket is not started');
        }

        this.socket.emit(eventName, data);
    }

    close() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }

    private listenSocket(socket: Socket, eventType: EventType, isValidData: (data: unknown) => boolean) {
        socket.on(eventType, (data: unknown) => {
            if (isValidData(data)) {
                this.eventListeners[eventType]?.forEach((listener) => listener(data));
            }
        });
    }
}
