import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, CircularProgress } from '@mui/material';
import styled from 'styled-components';
import { useApiClient } from '../../base/hooks/useApiClient';
import { useNavigate, useSearchParams } from 'react-router-dom';

const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
`;

const StyledForm = styled.form`
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const StyledButton = styled(Button)`
    && {
        background-color: #101010;
        color: #ececf1;
        border-radius: 1000px;
        padding: 8px 24px;
        min-height: 32px;
        transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
        &:hover {
            background-color: #404040;
        }
    }
`;

export function NewPasswordPage() {
    const [newPassword, setNewPassword] = useState('');
    const [formError, setFormError] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const apiClient = useApiClient();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);

        const response = await apiClient.post('update-password', { token, newPassword });

        if (!response.ok) {
            const { error } = await response.json();
            setFormError(error);
            setIsLoading(false);
            return;
        }

        setSuccessMessage('Your password has been updated successfully.');
        setFormError(undefined);
        setIsLoading(false);

        setTimeout(() => {
            navigate('/login');
        }, 3000);
    };

    return (
        <StyledContainer maxWidth="sm">
            <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Set New Password
                </Typography>
                <StyledForm onSubmit={handleSubmit}>
                    {formError && (
                        <Typography color="error" variant="body1" gutterBottom>
                            {formError}
                        </Typography>
                    )}
                    {successMessage && (
                        <Typography textAlign="center" color="primary" variant="body1" gutterBottom>
                            {successMessage}
                        </Typography>
                    )}
                    {!successMessage && (
                        <>
                            <TextField
                                label="New Password"
                                type="password"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                                disabled={isLoading}
                            />
                            <StyledButton type="submit" variant="contained" fullWidth disabled={isLoading}>
                                {isLoading ? <CircularProgress size={24} /> : 'Update Password'}
                            </StyledButton>
                        </>
                    )}
                </StyledForm>
            </Box>
        </StyledContainer>
    );
}
