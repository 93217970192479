import React, {useEffect, useState} from 'react';
import { Box, Tooltip } from '@mui/material';
import {EventType, WebSocketClient} from "../../../apis";

interface Props {
    websocket: WebSocketClient;
}

export function SessionStatusIndicator({ websocket }: Props) {
    const [isActive, setActive] = useState(false);

    useEffect(() => {
        const onSessionStarted = async () => {
            setActive(true);
        };

        const onSessionStopped = async () => {
            setActive(false);
        };

        websocket.addEventListener(EventType.SessionStarted, onSessionStarted);
        websocket.addEventListener(EventType.SessionStopped, onSessionStopped);

        return () => {
            websocket.removeEventListener(EventType.SessionStarted, onSessionStarted);
            websocket.removeEventListener(EventType.SessionStopped, onSessionStopped);
        };
    }, []);

    const status = isActive ? 'Session is running' : 'Session is not started';

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}>
            <Tooltip title={status} arrow enterTouchDelay={0}>
                <span
                    style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: isActive ? 'green' : 'transparent',
                        border: `4px solid ${isActive ? 'green' : 'red'}`,
                        display: 'inline-block',
                        marginLeft: '10px',
                    }}
                />
            </Tooltip>
        </Box>
    );
}
