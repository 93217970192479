import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.gray[900]};
  color: ${({ theme }) => theme.colors.gray[400]};
  padding: ${({ theme }) => theme.spacing[8]} 0;
  text-align: center;
`;

const FooterContent = styled.div`
  max-width: ${({ theme }) => theme.maxWidth.container};
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing[4]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
`;

const EmailLink = styled.a`
  color: ${({ theme }) => theme.colors.gray[400]};
  transition: color 0.2s;
  font-size: 1.125rem;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Copyright = styled.p`
  color: ${({ theme }) => theme.colors.gray[400]};
  font-size: 0.875rem;
`;

export default function Footer() {
  return (
      <FooterContainer>
        <FooterContent>
          <EmailLink href={`mailto:${process.env.REACT_APP_APP_URL}`}>
            {process.env.REACT_APP_APP_URL}
          </EmailLink>
          <Copyright>
            © {new Date().getFullYear()} SAI. All rights reserved.
          </Copyright>
        </FooterContent>
      </FooterContainer>
  );
}
