import {useEffect, useMemo} from "react";
import {WebSocketClient} from "../../../apis";

export function useWebsocket(path: string, connect = true) {
    const websocket = useMemo(() => new WebSocketClient(), []);

    useEffect(() => {
        if (connect) {
            websocket.connect(path);
        }
    }, [path, websocket, connect]);

    return websocket;
}
