import {Box, Card, CardContent, Chip, styled, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {EventType, WebSocketClient} from "../../../apis";

interface Props {
    websocket: WebSocketClient;
    session?: {
        originalLanguage: string;
        translationLanguages: string[];
    };
    supportedLanguages?: Record<string, string>;
}

const Content = styled(CardContent)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    
    &:last-child {
        padding-bottom: 16px;
    }
`;

export function SessionInfo({ websocket, session, supportedLanguages }: Props) {
    const [listeners, setListeners] = useState<Record<string, number>>({});

    useEffect(() => {
        const onListenersChanged = async ({ language, count }: { language: string, count: number }) => {
            setListeners({ ...listeners, [language]: Number(count) });
        };

        websocket.addEventListener(EventType.ListenersChanged, onListenersChanged);

        return () => {
            websocket.removeEventListener(EventType.ListenersChanged, onListenersChanged);
        };
    }, []);

    return (
        <Card variant="outlined" sx={{ borderRadius: '16px' }}>
            <Content>
                <Box>
                    <Typography variant="caption" sx={{ color: '#6e6e7f' }}>From </Typography>
                    {supportedLanguages && session && (
                        <Chip key={session.originalLanguage}
                              label={supportedLanguages[session.originalLanguage]!}
                        />)}
                </Box>
                <Box display="flex" alignItems="center" flexWrap="wrap" gap="10px">
                    <Typography variant="caption" sx={{ color: '#6e6e7f' }}> to: </Typography>
                    {supportedLanguages && session?.translationLanguages.map(translationLanguage => (
                        <Chip key={translationLanguage}
                              label={(
                                  <>
                                      {supportedLanguages[translationLanguage]}
                                      <span style={{ fontWeight: 600 }}> {listeners[translationLanguage] ?? 0}</span>
                                  </>
                              )}
                        />
                    ))}
                </Box>
            </Content>
        </Card>
    );
}
