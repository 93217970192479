import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiClient } from "../../base/hooks/useApiClient";
import { Box, FormControl, Modal, TextField, Typography, Autocomplete, Alert } from '@mui/material';
import { Button } from '../../base';
import styled from "styled-components";
import { useAsync } from "react-use";
import _ from 'lodash';

interface LanguageOption {
    value: string;
    label: string;
}

const StyledModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 450px;
    transform: translateY(-50px); // visually it looks more centered

    &:focus-visible {
        outline: none;
    }
`;

const StyledForm = styled.form`
    width: 350px;
    display: flex;
    flex-direction: column;
`;

const StyledButton = styled(Button)`
    && {
        background-color: #101010;
        color: #ececf1;
        border-radius: 1000px;
        padding: 8px 24px;
        min-height: 32px;
        transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
        &:hover {
            background-color: #404040;
        }
    }
`;

export function CreateSessionButton() {
    const apiClient = useApiClient();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [originalLanguage, setOriginalLanguage] = useState('');
    const [translationLanguages, setTranslationLanguages] = useState<LanguageOption[]>([]);
    const [errors, setErrors] = useState<{ name?: string, originalLanguage?: string, translationLanguages?: string }>({});
    const [generalError, setGeneralError] = useState<string | null>(null);

    const { value: supportedLanguagesConfig = {} } = useAsync(async () => {
        const response = await apiClient.get('supported-languages');

        if (!response.ok) {
            throw new Error('Cannot fetch list of supported languages');
        }

        const data = await response.json() as { supportedLanguages: Record<string, string> };

        return data.supportedLanguages;
    }, []);

    const supportedLanguages: LanguageOption[] = useMemo(() => {
        return Object.entries(supportedLanguagesConfig).map(([value, label]) => ({ value, label }));
    }, [supportedLanguagesConfig]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrors({});
        setGeneralError(null);

        const response = await apiClient.post('sessions', {
            name,
            originalLanguage,
            translationLanguages: translationLanguages.map((lang) => lang.value)
        });

        if (!response.ok) {
            const errorData = await response.json();
            if (errorData.errors) {
                const newErrors = _.fromPairs(errorData.errors.map((error: { path: string, message: string }) => [error.path, error.message]));
                setErrors(newErrors);
            }
            if (errorData.error) {
                setGeneralError(errorData.error);
            }
            return;
        }

        const { sessionName } = await response.json();
        navigate(`/panel/sessions/${sessionName}`);
        setOpen(false);
    };

    return (
        <>
            <Button onClick={() => setOpen(true)}>
                Create Session
            </Button>
            <StyledModal open={open} onClose={() => setOpen(false)}>
                <StyledContainer>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Create Session
                    </Typography>
                    {generalError && (
                        <Alert severity="error" sx={{ width: '100%', marginBottom: '16px' }}>
                            {generalError}
                        </Alert>
                    )}
                    <StyledForm onSubmit={handleSubmit}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onInput={(e) => {
                                const input = e.target as HTMLInputElement;
                                input.value = input.value.replace(/[^a-zA-Z0-9-]/g, '');
                            }}
                            error={!!errors.name}
                            helperText={errors.name || "Name can only contain letters, numbers, and hyphens."}
                        />
                        <Autocomplete
                            options={supportedLanguages}
                            getOptionLabel={(option) => option.label}
                            onChange={(_event, newValue) => {
                                setOriginalLanguage(newValue ? newValue.value : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Original Language"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                    error={!!errors.originalLanguage}
                                    helperText={errors.originalLanguage}
                                />
                            )}
                        />
                        <Autocomplete
                            multiple
                            options={supportedLanguages}
                            getOptionLabel={(option) => option.label}
                            onChange={(_event, newValue) => {
                                setTranslationLanguages(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Translation Languages"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={!!errors.translationLanguages}
                                    helperText={errors.translationLanguages}
                                />
                            )}
                        />
                        <FormControl sx={{ marginTop: '20px' }}>
                            <StyledButton type="submit" fullWidth>
                                Create Session
                            </StyledButton>
                        </FormControl>
                    </StyledForm>
                </StyledContainer>
            </StyledModal>
        </>
    );
}
