import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { EventType, WebSocketClient } from "../../../apis";

interface Props {
    websocket: WebSocketClient;
    eventType: EventType;
}

const Container = styled.div`
    flex-grow: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    max-height: 100%;
    width: 100%;
    flex-shrink: 0;
    min-height: 0;
`;

const Title = styled.div`
    flex-shrink: 0;
    padding-bottom: 8px;
    position: relative;
    font-size: 14px;
    color: #6e6e7f;
`;

const Content = styled.div`
    color: #101010;
    position: relative;
    flex-grow: 1;
    padding: 4px 0 8px;
    line-height: 1.2em;
    overflow: auto;
`;

const Text = styled.pre`
    display: block;
    white-space: pre-wrap;
    overflow: hidden;
    
    &:first-child {
        margin-top: 0;
    }
`;

export function Stenographer({ websocket, eventType }: Props) {
    const [values, setValues] = useState<string[]>([]);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onMessage = async (data: string) => {
            setValues((prev) => [...prev, data]);
        };

        websocket.addEventListener(eventType, onMessage);

        return () => {
            websocket.removeEventListener(eventType, onMessage);
        };
    }, []);

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.scrollTop = contentRef.current.scrollHeight;
        }
    }, [values]);

    return (
        <Container>
            <Title>{eventType}</Title>
            <Content ref={contentRef}>
                {values.length > 0 && values.map((value, index) => (
                    <Text key={index}>{value}</Text>
                ))}
                {values.length === 0 && `awaiting ${eventType}s...`}
            </Content>
        </Container>
    );
}
