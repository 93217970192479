import {CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

interface Props {
    loading: boolean;
    disabled?: boolean;
    value: string;
    onChange: (value: string) => void;
    options: { value: string, label: string }[];
}

export function LanguageSelector(props: Props) {
    const { loading, disabled, value, onChange, options } = props;

    if (loading) {
        return (
            <CircularProgress size={24} style={{ minHeight: '56px' }} />
        );
    }

    return (
        <FormControl variant="outlined">
            <InputLabel id="language-select-label">Language</InputLabel>
            <Select
                labelId="language-select-label"
                variant="outlined"
                label="Language"
                value={value}
                disabled={disabled}
                onChange={(event) => onChange(event.target.value)}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}