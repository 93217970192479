import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAsyncRetry } from 'react-use';
import { useApiClient } from "../../base/hooks/useApiClient";
import styled from "styled-components";
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { CreateSessionButton } from "./CreateSessionButton";
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Autocomplete, Alert, Box, Typography, FormControl, Modal } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import _ from 'lodash';

interface Session {
    id: number;
    name: string;
    createdAt: string;
    originalLanguage: string;
    translationLanguages: string[];
}

const Actions = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 16px;
`;

const StyledLink = styled(Link)`
    text-decoration: unset;
    color: unset;
    display: block;
    width: 100%;
    height: 100%;
`;

const StyledDataGrid = styled(DataGrid)`
    && {
        border: none;
        font-family: "Roboto Mono", monospace;

        .MuiDataGrid-columnHeaderTitle {
            font-weight: bold;
        }

        .MuiDataGrid-columnSeparator {
            display: none;
        }
    }
`;

const StyledModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 450px;
    transform: translateY(-50px); // visually it looks more centered

    &:focus-visible {
        outline: none;
    }
`;

const StyledForm = styled.form`
    width: 350px;
    display: flex;
    flex-direction: column;
`;

const StyledButton = styled(Button)`
    && {
        background-color: #101010;
        color: #ececf1;
        border-radius: 1000px;
        padding: 8px 24px;
        min-height: 32px;
        transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
        &:hover {
            background-color: #404040;
        }
    }
`;

export function SessionTablePage() {
    const apiClient = useApiClient();
    const navigate = useNavigate();
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedSessionName, setSelectedSessionName] = useState<string | null>(null);
    const [sessionData, setSessionData] = useState<Session | null>(null);
    const [name, setName] = useState('');
    const [originalLanguage, setOriginalLanguage] = useState('');
    const [translationLanguages, setTranslationLanguages] = useState<{ value: string, label: string }[]>([]);
    const [errors, setErrors] = useState<{ name?: string, originalLanguage?: string, translationLanguages?: string }>({});
    const [generalError, setGeneralError] = useState<string | null>(null);

    const { value: sessions, retry } = useAsyncRetry(async () => {
        const response = await apiClient.get('sessions');

        if (!response.ok) {
            throw new Error('Cannot fetch list of sessions');
        }

        const data = await response.json() as { sessions: Session[] };

        return data.sessions;
    }, []);

    const { value: supportedLanguagesConfig = {} } = useAsyncRetry(async () => {
        const response = await apiClient.get('supported-languages');

        if (!response.ok) {
            throw new Error('Cannot fetch list of supported languages');
        }

        const data = await response.json() as { supportedLanguages: Record<string, string> };

        return data.supportedLanguages;
    }, []);

    const supportedLanguages = Object.entries(supportedLanguagesConfig).map(([value, label]) => ({ value, label }));

    const handleOpenEditDialog = (name: string) => {
        const session = sessions?.find((session) => session.name === name);
        if (session) {
            setSessionData(session);
            setName(session.name);
            setOriginalLanguage(session.originalLanguage);
            setTranslationLanguages(session.translationLanguages.map(lang => ({ value: lang, label: supportedLanguagesConfig[lang] })));
        }
        setSelectedSessionName(name);
        setOpenEdit(true);
    };

    const handleOpenDeleteDialog = (name: string) => {
        setSelectedSessionName(name);
        setOpenDelete(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEdit(false);
        setSelectedSessionName(null);
        setSessionData(null);
        setName('');
        setOriginalLanguage('');
        setTranslationLanguages([]);
        setErrors({});
        setGeneralError(null);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDelete(false);
        setSelectedSessionName(null);
    };

    const handleConfirmDelete = async () => {
        if (selectedSessionName !== null) {
            const response = await apiClient.delete(`sessions/${selectedSessionName}`);

            if (!response.ok) {
                console.error('Error deleting session');
                return;
            }

            retry();
        }
        handleCloseDeleteDialog();
    };

    const handleEdit = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrors({});
        setGeneralError(null);

        if (selectedSessionName !== null) {
            const response = await apiClient.put(`sessions/${selectedSessionName}`, {
                name,
                originalLanguage,
                translationLanguages: translationLanguages.map((lang) => lang.value)
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.errors) {
                    const newErrors = _.fromPairs(errorData.errors.map((error: { path: string, message: string }) => [error.path, error.message]));
                    setErrors(newErrors);
                }
                if (errorData.error) {
                    setGeneralError(errorData.error);
                }
                return;
            }

            retry();
            handleCloseEditDialog();
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => (
                <StyledLink to={`/panel/sessions/${params.value}`}>{params.value}</StyledLink>
            ),
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 1,
            valueFormatter: (value: string) => new Date(value).toLocaleString(),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => handleOpenEditDialog(params.row.name)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteDialog(params.row.name)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            ),
        },
    ];

    const rows: GridRowsProp = sessions?.map((session) => ({
        id: session.id,
        name: session.name,
        createdAt: session.createdAt,
    })) || [];

    return (
        <>
            <Actions>
                <CreateSessionButton />
            </Actions>
            <StyledDataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 15 },
                    },
                }}
                pagination
                disableColumnResize
            />
            <Dialog
                open={openDelete}
                onClose={handleCloseDeleteDialog}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this session?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <StyledModal open={openEdit} onClose={handleCloseEditDialog}>
                <StyledContainer>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Edit Session
                    </Typography>
                    {generalError && (
                        <Alert severity="error" sx={{ width: '100%', marginBottom: '16px' }}>
                            {generalError}
                        </Alert>
                    )}
                    <StyledForm onSubmit={handleEdit}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onInput={(e) => {
                                const input = e.target as HTMLInputElement;
                                input.value = input.value.replace(/[^a-zA-Z0-9-]/g, '');
                            }}
                            error={!!errors.name}
                            helperText={errors.name || "Name can only contain letters, numbers, and hyphens."}
                        />
                        <Autocomplete
                            options={supportedLanguages}
                            getOptionLabel={(option) => option.label}
                            onChange={(_event, newValue) => {
                                setOriginalLanguage(newValue ? newValue.value : '');
                            }}
                            value={supportedLanguages.find(lang => lang.value === originalLanguage) || null}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Original Language"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                    error={!!errors.originalLanguage}
                                    helperText={errors.originalLanguage}
                                />
                            )}
                        />
                        <Autocomplete
                            multiple
                            options={supportedLanguages}
                            getOptionLabel={(option) => option.label}
                            onChange={(_event, newValue) => {
                                setTranslationLanguages(newValue);
                            }}
                            value={translationLanguages}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Translation Languages"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={!!errors.translationLanguages}
                                    helperText={errors.translationLanguages}
                                />
                            )}
                        />
                        <FormControl sx={{ marginTop: '20px' }}>
                            <StyledButton type="submit" fullWidth>
                                Save Changes
                            </StyledButton>
                        </FormControl>
                    </StyledForm>
                </StyledContainer>
            </StyledModal>
        </>
    );
}
