import React, {useState} from 'react';
import {PlayPauseButton} from "../../base/PlayPauseButton";
import {useWavRecorder} from "./hooks/useWavRecorder";
import {usePlayback, useWebsocket} from "../../base/hooks";
import {Stenographer} from "../../base";
import {Actions, Breadcrumbs} from "../../layout";
import {RoomQRCode} from "./RoomQRCode";
import {useParams} from 'react-router-dom';
import {Box, Grid2} from "@mui/material";
import {CopySessionListenLinkButton} from "./CopyLinkButton";
import {SessionInfo} from "./SessionInfo";
import {useApiClient} from "../../base/hooks/useApiClient";
import {useAsync} from "react-use";
import {EventType} from "../../../apis";
import {LanguageSelector} from "../session-listen/LanguageSelector";

export function SessionPage() {
    const { sessionName } = useParams<{ sessionName: string }>();
    const websocket = useWebsocket(`/sessions/${sessionName}`);
    const recorder = useWavRecorder();
    const apiClient = useApiClient();
    const { playback } = usePlayback(websocket);

    const { value: session, loading: sessionLoading } = useAsync(async () => {
        const response = await apiClient.get(`sessions/${sessionName}`);

        if (!response.ok) {
            console.error('Error fetching session data');
        }

        const session = await response.json();

        return session as { name: string, originalLanguage: string, translationLanguages: string[] };
    });

    const { value: supportedLanguages, loading: supportedLanguagesLoading } = useAsync(async () => {
        const response = await apiClient.get('supported-languages');

        if (!response.ok) {
            console.error('Error fetching session data');
        }

        const data = await response.json() as { supportedLanguages: Record<string, string> };

        return data.supportedLanguages;
    });

    const onStartPlaying = async () => {
        await websocket.send(EventType.StartSession);

        await recorder.start((data) => {
            websocket.send(EventType.Audio, data.mono);
        });
    };

    const onStopPlaying = async () => {
        await recorder.stop();
        await websocket.send(EventType.StopSession);
    }

    const [playbackLanguage, setPlaybackLanguage] = useState<string>('disabled');

    const handlePlaybackLanguageChange = async (language: string) => {
        if (language === playbackLanguage) {
            return;
        }

        setPlaybackLanguage(language);
        await websocket.send(EventType.MasterPlaybackLanguage, language);

        if (language === 'disabled') {
            playback.disable();
        } else if (playbackLanguage === 'disabled') {
            playback.enable();
        }
    }

    return (
        <Grid2 container direction="column" gap={2} sx={{ height: '100%' }}>
            <Grid2 size={{ xs: 12 }}>
                <Breadcrumbs items={[
                    { label: 'Sessions', path: '/panel/sessions' },
                    { label: sessionName! }
                ]} />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
                <SessionInfo websocket={websocket} session={session} supportedLanguages={supportedLanguages} />
            </Grid2>
            <Grid2 size={{ xs: 12 }} sx={{ flexGrow: 1, height: '10%' }}>
                <Stenographer websocket={websocket} eventType={EventType.Transcript} />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
                <Actions>
                    <Box display="flex" gap="10px">
                        <RoomQRCode translationLanguages={session?.translationLanguages} supportedLanguages={supportedLanguages} />
                        <CopySessionListenLinkButton />
                    </Box>
                    <Box display="flex" gap="10px">
                        <LanguageSelector
                            loading={sessionLoading || supportedLanguagesLoading}
                            value={playbackLanguage}
                            onChange={handlePlaybackLanguageChange}
                            options={[{ value: 'disabled', label: 'Disabled Playback' }, ...session?.translationLanguages?.map((availableLanguage) => ({
                                value: availableLanguage,
                                label: supportedLanguages?.[availableLanguage] ?? availableLanguage,
                            })) ?? []]}
                        />
                        <PlayPauseButton
                            playText="start"
                            pauseText="stop"
                            onStart={onStartPlaying}
                            onStop={onStopPlaying}
                        />
                    </Box>
                </Actions>
            </Grid2>
        </Grid2>
    );
}
